<template>
    <div
        :id="modalInternal"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-1" v-if="forUpdate == true">
                        {{ this.$i18n.t("button.edit") }}
                    </h5>
                    <h5 class="modal-title">
                        {{ this.$i18n.t("formInternal.title") }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submit()">
                        <div class="form-group">
                            <label for="inputTanggal">{{
                                this.$i18n.t("formInternal.date")
                            }}<span style="color:red;">*</span></label>
                            <VueDatePicker
                                v-model="person.tanggal"
                                
                                :validation="'required'"
                                :validationName="'tanggal'"
                                :name="'tanggal'"
                                @change="changeDate"
                            />
                        </div>
                        <div class="form-group">
                            <label for="inputPilihDoctor">{{
                                this.$i18n.t("formInternal.select")
                            }}<span style="color:red;">*</span></label>
                            <vue-multi-select
                                :options="doctorsName"
                                v-model="selectedDoctor"
                                :custom-label="nameLang"
                                :name="'doktor'"
                                track-by="id"
                                :validation="'required'"
                                :validationName="'Doktor'"
                                :search-change="searchDokter"
                                :loading="isLoadingDokter"
                                :isShowSearchNote="true"
                                :isClearOnSelect="false"
                                :disabled="!person.tanggal"
                            >
                                <template v-slot:selectionTemplate="{data}">
                                    <slot name="selectionTemplateSlot" :data="data">
                                    </slot>
                                </template>
                                <template v-slot:optionsTemplate="{data}">
                                <div class="option__desc">
                                        <span class="option__title d-block">{{ data.option.nama }}</span>
                                        <span class="option__small d-block fs-9"> ( {{ data.option.poli }} )</span>
                                </div> 
                                </template>
                            </vue-multi-select>
                            <p v-if="!person.tanggal">
                                Pilih tanggal terlebih dahulu
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="inputJam">{{
                                this.$i18n.t("formInternal.pilihJam")
                            }}<span style="color:red;">*</span></label>
                            <!-- <select
                                :disabled="!getDay.length"
                                class="form-control"
                                name="namaDokter"
                                v-model="queueId"
                            >
                                <option
                                    v-for="jam in getDay"
                                    :key="jam.id"
                                    :value="jam.id"
                                    >{{ jam.jamMulai }} - {{ jam.jamSelesai }}
                                </option>
                            </select> -->
                            <Multiselect
                                name="slotType"
                                v-model="queueObj"
                                :options="getDay"
                                :multiple="false"
                                :custom-label="pilihJamLabel"
                                track-by="id"
                                :loading="isGetJadwalLoading"
                                :disabled="isGetJadwalLoading || !getDay.length"
                                placeholder="Pilih jam praktek"
                            > 

                            </Multiselect>
                            <p v-if="!getDay.length && selectedDoctor && !isGetJadwalLoading">
                                {{selectedDoctor.nama}} tidak memiliki jadwal/jam praktek
                            </p>
                            <p v-else-if="isGetJadwalLoading && selectedDoctor">
                                Sedang mencari jadwal/jam praktek milik {{selectedDoctor.nama}}
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="inputJamMulai">{{
                                this.$i18n.t("formInternal.jamMulai")
                            }}<span style="color:red;">*</span></label>
                            <JadwalTime
                                :time-id="'jadwal_mulai'"
                                :place-holder="'Jam Mulai'"
                                :value="person.jamMulai"
                                :changedValue.sync="person.jamMulai"
                                :is-invalid="isInvalid"
                            />
                            <span class="invalid-feedback d-block" v-if="isInvalid">
                                Jam harus sesuai jadwal
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="inputJamSelesai">{{
                                this.$i18n.t("formInternal.jamSelesai")
                            }}<span style="color:red;">*</span></label>
                            <JadwalTime
                                :time-id="'jadwal_selesai'"
                                :place-holder="'Jam Selesai'"
                                :value="person.jamSelesai"
                                :changedValue.sync="person.jamSelesai"
                                :is-invalid="isInvalid"
                            />
                            <span class="invalid-feedback d-block" v-if="isInvalid">
                                Jam harus sesuai jadwal
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="inputRemark"
                                >{{ this.$i18n.t("formInternal.remark") }} (
                                akan ditampilkan ke pasien via mobile app
                                )</label
                            >
                            <VueInputText
                                v-model="person.remark"
                                :validationName="'Nama'"
                                :validation="'required'"
                                :placeholder="'Remark'"
                            />
                        </div>
                        <div class="form-group">
                            <label for="inputSlotType">{{
                                this.$i18n.t("formInternal.slotType")
                            }}<span style="color:red;">*</span></label>
                            <Multiselect
                                name="slotType"
                                v-model="person.slotType"
                                :options="slotTypeOptions"
                                :multiple="false"
                                :custom-label="noStrip"
                            > 

                            </Multiselect>
                            <!-- <select
                                class="form-control"
                                name="slotType"
                                v-model="person.slotType"
                                placeholder="tipe slot"
                            >
                                <option value="TELAT">Telat</option>
                                <option value="INTERNAL_EVENT"
                                    >Kejadian Internal</option
                                >
                            </select> -->
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="isDisableButton"
                        @click.prevent="submit()"
                    >
                        {{ forUpdate ? "Ubah" : "Submit" }}
                    </button>
                    <!-- <button v-if="forUpdate" type="submit" class="btn btn-primary" @click.prevent="submitUpdate()">{{this.$i18n.t("button.edit")}}</button>
                      <button v-else type="submit" class="btn btn-primary" @click.prevent="submit()">Submit</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions,mapGetters } from "vuex";

import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const SlotJadwalResource = RepositoryFactory.get("SlotJadwalRes");
const DoctorRepository = RepositoryFactory.get("doctor");
const QueueUnitRepository = RepositoryFactory.get("queueUnit");

import SweetAlertTemplate from "./../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../../_js/BlockUI";
const blockUI = new BlockUI();
import VueInputText from "./../../_general/VueInputText";
import JadwalTime from "./../../_select/JadwalTime";
import VueDatePicker from "./../../_general/VueDatePicker";
import vueMultiSelect from "./../../_select/VueMultiSelect";
import RenderDataFullCalendar from "../../../services/render-fullcalendar";
const renderDataFullCalendar = new RenderDataFullCalendar()

import Multiselect from 'vue-multiselect'

import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
    data() {
        return {
            person: {
                jamMulai: null,
                jamSelesai: null,
                queueUnit: {
                    id: null
                },
                remark: null,
                slotType: null,
                tanggal: null
            },
            listDoctorName: [],
            selectedDoctor: null,
            indexDoctor: null,
            jadwalList: null,
            // queueId: null,
            queueObj: null,
            payloadUpdate: {
                jamMulai: null,
                jamSelesai: null,
                slotJadwalId: null
            },
            slotData: null,
            doctorLogin: checkPermission.isCanAccess(
                "DokterResource.GET.Dokter.Current"
            ),
            getDay: [],
            slotTypeOptions: ['TELAT' , 'INTERNAL_EVENT' ],
            isGetJadwalLoading: false,
            isLoadingDokter: false,
            searchDokter: _.debounce( query => {
                if (query) {
                    this.getListDokters({
                    date: this.person.tanggal,
                    specialties: this.spesialisasiIdAndSub } , query)
                } else {
                    this.getListDokters({
                    date: this.person.tanggal,
                    specialties: this.spesialisasiIdAndSub } )
                }
            }, 500 ),
            abortController: []
        };
    },
    components: {
        VueInputText,
        JadwalTime,
        VueDatePicker,
        vueMultiSelect,
        Multiselect
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        modalInternal: { type: String, default: "internalModal" }
    },
    watch: {
        selectedDoctor: {
            deep: true,
            handler(val){
                if (val) {
                    if (val.id && this.person.tanggal) {
                        this.getJadwal(val.id)
                        this.queueId = null
                    }
                }
            }
        },
        'person.tanggal': {
            deep: true,
            handler(val) {
                if (this.selectedDoctor && val) {
                    this.queueId = null
                    this.getJadwal(this.selectedDoctor.id)
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            setShowModalInternal: "DayCalendarStore/SET_SHOW_MODAL_INTERNAL",
            setForUpdate: "DayCalendarStore/SET_FOR_UPDATE"
        }),
        ...mapActions({
            RELOAD_CALENDAR: "DoctorAppointmentStore/RELOAD_CALENDAR",
            SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG"
        }),
        close() {
            $("#" + this.modalInternal).modal("hide");
            this.setShowModalInternal(false);
            this.setForUpdate(false);
        },
        submit() {
            if (
                !this.person.jamMulai ||
                !this.person.jamSelesai ||
                !this.person.tanggal ||
                !this.person.slotType ||
                !this.queueId
            ) {
                toastr.error("Mohon Periksa Kembali Kolom yang Kosong");
            } else {
                if (this.forUpdate) {
                    this.submitUpdate();
                } else {
                    const onConfirm = () => {
                        let prefix = "J";
                            prefix += this.queueId;
                            this.person.queueUnit.id = prefix;
                            this.create();

                            this.close();
                    }
                    new SweetAlert().showConfirm({
                        swalTitle: "Apakah Anda yakin?",
                        swalText: "Data Baru akan ditambahkan.",
                        swalType: "info",
                        onConfirmButton: onConfirm,
                        onCancelButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                }
            }
        },
        submitUpdate() {
            const onConfirm = () => {
                if (this.person.jamMulai.length < 5) {
                        this.person.jamMulai = "0" + this.person.jamMulai;
                    }
                    if (this.person.jamSelesai.length < 5) {
                        this.person.jamSelesai = "0" + this.person.jamSelesai;
                    }
                    this.update();
                    this.close();
            }
            new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Akan Diperbarui.",
                swalType: "info",
                onConfirmButton: onConfirm,
                onCancelButton: function() {
                    blockUI.unblockModal();
                }
            });
        },
        async getListDokters({date, poli, dokter , specialties}, query) {
            const actionName = "getListDokters"
            if (Array.isArray(this.abortController)) {
                this.abortController.forEach(each => {
                    if (each.actionName == actionName) {
                        each.contr.abort()
                    }
                });
                this.abortController = this.abortController.filter(each => {
                    return each.actionName != actionName
                })
            }
            try {
                let listDokters;
                if (this.doctorLogin) {
                    listDokters = new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors({}, resolve, reject);
                    });
                } else {
                   let req = {
                    "tanggalMulai.equals": date,
                    "tanggalSelesai.equals": date,
                    sort: 'asc',
                    page_size: 5,
                   }
                    // if(dokter || poli) {
                    //     // req = {
                    //     //     ...(dokter ? {'id.in': dokter.id}: {'tujuanRujukanId': poli.id})
                    //     // }
                    //     if (dokter) {
                    //         req['id.in'] = dokter.id
                    //     }
                    //     if (poli) {
                    //         req['tujuanRujukanId'] = poli.id
                    //     }
                    // } 
                    if (query) {
                        req.nama = query
                        req.page_size = 1000
                    }
                   if (specialties) {
                        const speObj = JSON.parse(specialties)
                        if (speObj.spesialisasiId) {
                            req.spesialisasi = speObj.spesialisasiId
                        }
                        if (speObj.subSpesialisasiId) {
                            req.subSpesialisasi = speObj.subSpesialisasiId
                        }
                    }
                    const dokterRepo = DoctorRepository.getDokterDropdown(req);

                    const abortController = await dokterRepo.next()
                    this.abortController.push({actionName: actionName , contr: abortController.value})
                    listDokters = dokterRepo.next()
                }
                this.isLoadingDokter = true
                const responseDokter = await listDokters
                this.isLoadingDokter = false
                // const responseDokter = await  DoctorRepository.getDokterDropdown(req);
                
                
                // var ids = new Set(dataArray.map(d => d.id));
                // var merged = [...dataArray, ...this.dokterArray.filter(d => !ids.has(d.id))];
                if (this.doctorLogin) {
                    this.listDoctorName = this.dokterArray
                } else {
                    this.listDoctorName = this.mergeTwoArraysById(responseDokter.value ,this.dokterArray);        
                }
            } catch (err) {
                this.isLoadingDokter = false
                let message = 'Error Unknown'
                if (err.message) {
                    message = err.message
                } 
                toastr.error(message);
            }
        },
        mergeTwoArraysById(array1,array2){
            let ids = new Set(array1.map(d => d.id));
            let merged = [...array1, ...array2.filter(d => !ids.has(d.id))];
            return merged
        },
        async getSLotJadwal() {
            try {
                const { data } = await SlotJadwalResource.getSlotJadwal(
                    `id.equals=${this.event.reservasi.id}`
                );
                if (data) {
                    this.slotData = data[0];
                    this.person.jamMulai = data[0].jamMulai;
                    this.person.jamSelesai = data[0].jamSelesai;
                    this.person.tanggal = data[0].tanggal;
                    this.person.remark = data[0].remark;
                    this.person.slotType = data[0].slotType;
                    this.selectedDoctor = {
                        id: data[0].queueUnit.jadwal.dokter.id,
                        nama: data[0].queueUnit.jadwal.dokter.nama,
                        poli: this.defaultSpesialisasi(data[0].queueUnit.jadwal.dokter.dokterSpesialisasis)
                    };
                    setTimeout(() => {
                        this.queueObj = data[0].queueUnit.jadwal;
                    }, 50);
                }
            } catch (err) {
                toastr.error("error get data");
            }
        },
        async update() {
            let payload = [
                    {
                        jamMulai: this.person.jamMulai,
                        jamSelesai: this.person.jamSelesai,
                        id: this.slotData.id
                        // slotjadwalId: vx.slotData.id
                    }
                ]
                payload[0] = {...this.person, ...payload[0]}
                await SlotJadwalResource.updateInternalEvent(payload)
                    .then(() => {
                            this.setForUpdate(false)
                            // vx.RELOAD_CALENDAR()
                            // vx.SOCK_MSG('doctor-appointment')
                            new SweetAlert().showInfo({
                                swalTitle: "Berhasil!",
                                swalText: "Slot berhasil diubah.",
                                swalType: "success",
                                onConfirmButton: function() {
                                    blockUI.unblockModal();
                                }
                            });
                    })
                    .catch(error => {
                        new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: "slot tidak dapat diubah.",
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    });
        },
        async create() {
            let payload = [];
            // if(!vx.person.jamMulai || !vx.person.jamSelesai){
            //   toastr.error('Mohon masukkan periksa jam mulai/jam selesai')
            // } else {
            payload.push(this.person);
            if (payload[0].jamMulai.length < 5) {
                payload[0].jamMulai = "0" + payload[0].jamMulai;
            }
            if (payload[0].jamSelesai.length < 5) {
                payload[0].jamSelesai = "0" + payload[0].jamSelesai;
            }
            await SlotJadwalResource.createSlotJadwals(payload)
                .then(() => {
                    this.RELOAD_CALENDAR();
                    new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Internal Event baru berhasil ditambahkan.",
                        swalType: "success",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                })
                .catch(error => {
                    new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "slot baru tidak dapat dibuat.",
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
            // }
        },
        nameLang({nama, poli}){
            return `${nama} \n ${poli}`
        },
        changeDate(evt) {
            if (evt) {
                this.getListDokters({
                    date: evt,
                    poli: this.poli,
                    dokter: this.dokter,
                    specialties: this.spesialisasiIdAndSub
                })
            } else {
                this.listDoctorName = []
                this.selectedDoctor = null
            }
        },
        async getJadwal(dokterId) {
            try {
                const momentDay = moment(this.person.tanggal).format("YYYY-MM-DD");
                const paramQueue = {
                    tanggal: momentDay,
                    dokterId: dokterId
                }
                this.isGetJadwalLoading = true
                const response = await new Promise(
                        (resolve, reject) => {
                            QueueUnitRepository.geQueueUnitSlot(
                                paramQueue,
                                resolve,
                                reject
                            );
                        }
                    );
                this.isGetJadwalLoading = false
                
                this.getDay = response.data.map(data => {
                    return data.jadwal
                }).sort((a, b) => a.jamMulai.localeCompare(b.jamMulai))
            } catch (error) {
                this.isGetJadwalLoading = false
            }
        },
        noStrip(label){
            let labelString = "-"
            if (label) {
               labelString = label.replace(/_+/g, ' ')
            }
            return labelString

        },
        pilihJamLabel({jamMulai ,jamSelesai }){
            let labelString = "-"
            if (jamMulai && jamSelesai) {
                labelString = `${jamMulai} - ${jamSelesai}`
            }
            return labelString
        },
        defaultSpesialisasi(array){
            if (Array.isArray(array)) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].isDefault || array[i].default) {
                        if (array[i].spesialisasi) {
                            return this.spesialisasiMapper(array[i].spesialisasi)
                        }
                    }
                }
            }
        },
        spesialisasiMapper(spesialisasi){
            if (spesialisasi) {
                return  spesialisasi.namaIndo
            }
        }
    },
    computed: {
        isInvalid () {
            if (this.isDisableButton && this.person.jamMulai && this.person.jamSelesai) return true
            return false
        },
        isDisableButton () {
            if (this.person.jamMulai && this.person.jamSelesai && this.queueObj && this.queueObj.id) {
                const jamMulai = this.person.jamMulai
                const jamSelesai = this.person.jamSelesai
                const jadwalMulai = this.queueObj.jamMulai
                const jadwalSelesai = this.queueObj.jamSelesai
                const isCheck = renderDataFullCalendar.haveClaimInSlotJadwal(jamMulai, jamSelesai, jadwalMulai, jadwalSelesai)
                if(isCheck) return false
            }
            return true
        },
        ...mapState("DayCalendarStore", {
            event: state => state.selectedEvent,
            forUpdate: state => state.forUpdate,
            
        }),
         ...mapState("DoctorAppointmentStore", {
            poli: state => state.poli,
            dokter: state => state.dokter,
            dokterArray: state => state.dokterArray,
            // selectedSpecialties: state => state.selectedSpecialties,
        }),
        ...mapGetters({
            spesialisasiIdAndSub: "DoctorAppointmentStore/GET_SPESIALISASI_ID_AND_SUB"
        }),
        // getDay: function() {
        //     if (this.person.tanggal && this.selectedDoctor) {
        //         //filter nama doctor
        //         let result = this.listDoctorName.filter(
        //             word => word.id == this.selectedDoctor.id
        //         );

        //         if (result[0]) {
        //             let hari = [
        //                 "SUNDAY",
        //                 "MONDAY",
        //                 "TUESDAY",
        //                 "WEDNESDAY",
        //                 "THURSDAY",
        //                 "FRIDAY",
        //                 "SATURDAY"
        //             ];
        //             let d = new Date(this.person.tanggal);
        //             let dayName = hari[d.getDay()];
        //             //filter jadwal berdasarkan nama hari
        //             let jadwals = result[0].jadwals.filter(
        //                 word => word.hari == dayName
        //             );
        //             return jadwals[0] ? jadwals[0].jam : [];
        //         }
        //     }
        //         return [];
        // },
        doctorsName: function() {
            let names = [];
            if (Array.isArray(this.listDoctorName)) {
                this.listDoctorName.forEach(el => names.push({id: el.id, nama: el.nama, poli: this.defaultSpesialisasi(el.dokterSpesialisasis)}));
            }
            return names;
        },
        queueId: {
            get(){
                if (this.queueObj) {
                    if (this.queueObj.id) {
                        return this.queueObj.id
                    }
                }
                return null
            },
            set(val){
                this.queueObj = val
            }
        }
    },
    // created(){
    //     this.getJadwal()
    // },
    mounted() {
        const date = this.$store.state.DoctorAppointmentStore.date
        this.person.tanggal = date
        this.getListDokters({
            date,
            poli: this.poli,
            dokter: this.dokter,
            specialties: this.spesialisasiIdAndSub
        })
        $("#" + this.modalInternal).modal();
        // this.getListDokters();
        if (this.forUpdate == true) {
            this.getSLotJadwal();
        }
    }
};
</script>

<style lang="scss" scoped></style>
